import * as utils from "./services/utils";
const apiPost = async (url, parameters) => {
  const res = await utils.default_post(url, parameters);
  // console.log("The Res >>>", res);
  if (res && res.data) {
    const result = {
      data: {
        data: res.data,
        meta: res.meta,
      },
    };
    // console.log("result >>>>>>>>", result);
    return result;
  }
};

export default apiPost;
