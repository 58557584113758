import React from "react";
import { useParams } from "react-router-dom";
import imgage from "../SiteImages/slide-1.jpg";
import apiPost from "../Utilis/apiPost";
import { backend } from "../Utilis/config";
import parse from "html-react-parser";

export default function DynamicPage({ type, section_id }) {
  const { id } = useParams();
  const [data, setData] = React.useState([]);
  const returned_url = () => {
    if (type === "page") return "/get_cms_pages";
    else return "/cms_front_get_subjects";
  };

  React.useEffect(() => {
    async function fetchData() {
      const data = apiPost(returned_url(), {
        id: id,
        section_type: section_id,
      })
        .then((res) => setData(res.data.data[0]))
        .then((res) => console.log(res, "res"));
    }
    fetchData();
  }, [id]);
  return (
    <div>
      <style>
        {`
                p, span {
                    font-family: almarai !important;
                }
                `}
      </style>
      <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark text-uppercase">
                <strong>{data?.name_ar}</strong>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-color-light position-relative border-0 mt-4">
        <div className="container container-xl-custom">
          <div className="row">
            <div
              className="col-lg-12"
              data-appear-animation="fadeInUpShorter"
              data-appear-animation-delay="700"
            >
              <article style={{ justifyContent: "center", display: "flex" }}>
                <div className="card border-0 border-radius-0 ">
                  <div className="card-body p-0 z-index-1">
                    <div className="post-image  " id="divthumb" runat="server">
                      <img
                        style={{
                          maxWidth: "1000px",
                          maxHeight: "600px",
                          height: "auto",
                          minWidth: "800px",
                        }}
                        id="ImgPhoto"
                        className="card-img-top border-radius-2"
                        src={`${backend}/cms/imgs/${data?.photo_id}`}
                        //src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/AbdelFattah_Elsisi.jpg/264px-AbdelFattah_Elsisi.jpg"
                      />
                    </div>
                    <div className="card-body p-0">
                      <p style={{ maxWidth: "800px", marginTop: 10 }}>
                        <span
                          id="lblDetails"
                          style={{
                            fontFamily: "almarai !important",
                            fontSize: 18,
                          }}
                        >
                          {parse(data?.details_ar ?? "")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
