import React from "react";
import News from "../Components/HomeNews";
import Events from "../Components/Events";
import Slider from "../Components/Slider";
import AllTenders from "./AllTenders";
import StaticsSection from "./StaticsSection";
export default function Home() {
  // const [data, setData] = React.useState([]);

  return (
    <div>
      <Slider />
      <News />
      <Events />
      <AllTenders limit={8} />
    </div>
  );
}
