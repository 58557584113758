export const types = {
  publication: 1,
  imageGallery: 2,
  videoGallery: 3,
  mainSlider: 4,
  partners: 5,
  news: 6,
  events: 7,
  articles: 8,
};
