import { create } from "apisauce";
import { backend } from "../config";
import * as tunnle from "./tunnle.js";
import * as utils from "./utils";
export const errorCodes = {
  syncError: 1,
  serverError: 2,
};
export const get = async (url, params, axiosConfig) => {
  const response = await get(url, params, axiosConfig);
  if (response.ok) {
    return response;
  }
  return null;
};
const handleCodes = {
  keepGoing: 1,
  stopSuccess: 2,
  stopFail: 3,
};
const handle_application_error = async (error) => {
  // //console.log("handle_application_error >>>>>>>>>>>");
  if (!error) return handleCodes.stopFail;
  // console.error(error);
  const { code /*, msg*/ } = error;
  if (code && code === errorCodes.syncError) {
    ////console.log("SYNC ERROROROROROR");
    if ((await tunnle.initClientKeys()) === tunnle.syncErrors.inSync)
      return handleCodes.stopSuccess;
    return handleCodes.keepGoing;
  }
  return handleCodes.stopFail;
};
const handles_server_error = (exc) => {
  // console.error(exc);
  return handleCodes.stopFail;
};
export const decrypt_response = async (response) => {
  const reso = await tunnle.defaultAesDecrypt(response.data);
  response.data = JSON.parse(reso);
};

export const encrypt_payload = async (params, tunnleObject) => {
  let encData = params;
  if (utils.securePayload)
    encData = await tunnle.defaultAesEncrypt(JSON.stringify(encData));
  return { has_files: false, encData };
};
export const post = async (
  encrypt,
  serviceUrl,
  params,
  axiosConfig,
  pageLocation
) => {
  const internalPost = async (encrypt, serviceUrl, params, axiosConfig) => {
    // console.log("Internal Posat");
    try {
      const api = create({
        baseURL: `${backend}/upa/cmsf`,
      });
      api.setHeaders({ "UPA-AUTH-HEAD": await tunnle.getToken() });
      // console.log("token >>>> " + (await tunnle.getToken()));
      const tunnleObject = await tunnle.createTunnleObject();
      api.setHeaders({
        "UPA-TOKEN-HEAD": JSON.stringify(tunnleObject),
      });
      params["upa-page-loc-route"] = JSON.stringify(pageLocation);

      // api.setHeaders({ "Content-Type": "multipart/form-data" });
      // console.log("BBBBBBBBBBBBBBBB");
      const { has_files, encData } = await encrypt_payload(
        params,
        tunnleObject
      );
      // console.log("AFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF", encData);

      // console.log(
      //   `has_files >> has_files : ${has_files}  encData : ${encData}`
      // );

      api.setHeaders({ "UPA-HAS-FILES": 0 });
      // console.log("COMING BACCCCCCCCCCCCCCCCCCCCCCCCCC");
      // console.log(encData);
      const response = await api.post(serviceUrl, encData, axiosConfig);
      // //console.log("AFTER BACKKKKKKKKKKKKKKKKKKKK");
      // console.log(response.data);
      // console.log("WILL utils.securePayload>>> " + utils.securePayload);

      if (utils.securePayload && !has_files) {
        // console.log("WILL DECRYPT PAYLOAD>>>");
        await decrypt_response(response);
      }
      const { /* data, meta, */ error } = response.data;

      if (!error || Object.keys(error).length) {
        const res = {
          success: false,
          errMangeResult: await handle_application_error(error),
          date: null,
          origError: error,
        };
        return res;
      } // Here No Error Code Returned
      const res = {
        success: response.ok ? true : false,
        errMangeResult: response.ok
          ? handleCodes.stopSuccess
          : handleCodes.stopFail,
        data: response.data,
      };
      return res;
    } catch (error) {
      // Internal Server Error (Server didn't responde)
      console.error(">>>>>>>>>>>>ENCO>>", error);
      // return handles_server_error(error);
      return {
        success: false,
        errMangeResult: handles_server_error(error),
        data: null,
      };
    }
  };
  let eCounter = 4;
  while (eCounter > 0) {
    const res = await internalPost(encrypt, serviceUrl, params, axiosConfig);
    //console.log(`Trial Number => ${eCounter}`);
    //console.log(res);
    if (res.success /*|| res.errMangeResult === handleCodes.stopSuccess*/)
      return res.data;
    if (res.errMangeResult === handleCodes.stopFail) {
      ////console.log("EWRRRR", res);
      // GoOut();
      return {
        data: [],
        meta: null,
        error: { isError: true },
        origError: res.origError,
      };
      // const noti = useNotification();
      // return res.data;
    }
    eCounter--;
  }
  GoOut();
  return [];
};
const GoOut = () => {
  console.log("BY BY");
};
