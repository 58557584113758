import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import AllNews from "./Pages/AllNews";
import DynamicPage from "./Components/DynamicPage";
import AllEvents from "./Pages/AllEvents";
import AllArticles from "./Pages/AllArticles";
import AllPublications from "./Pages/AllPublications";
import MainLayout from "./layout/MainLayout";
import AllTenders from "./Pages/AllTenders";
import FAQ from "./Pages/FAQ";
import { types } from "./Utilis/cat_types";
const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/news",
        element: <AllNews />,
      },
      {
        path: "/news/:id",
        element: <DynamicPage section_id={types.news} />,
      },
      {
        path: "/articles/:id",
        element: <DynamicPage type={"articles"} section_id={types.articles} />,
      },
      {
        path: "/events/:id",
        element: <DynamicPage type={"events"} section_id={types.events} />,
      },
      {
        path: "/events",
        element: <AllEvents />,
      },
      {
        path: "/articles",
        element: <AllArticles />,
      },
      {
        path: "/publications",
        element: <AllPublications />,
      },

      {
        path: "/pages/:id",
        element: <DynamicPage type="page" />,
      },
      {
        path: "/tenders",
        element: <AllTenders />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
    ],
  },
]);
root.render(
  <>
    <RouterProvider router={router} />
  </>
);

// reportWebVitals();
