export const backend = "https://con.upa.gov.eg";

var months = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];
var days = [
  "اﻷحد",
  "اﻷثنين",
  "الثلاثاء",
  "اﻷربعاء",
  "الخميس",
  "الجمعة",
  "السبت",
];
var date = new Date();
export var delDateString =
  days[date.getDay()] +
  ", " +
  date.getDate() +
  " " +
  months[date.getMonth()] +
  ", " +
  date.getFullYear();

// export const phone = "0125222544455";
// export const email = "sovghab@gmail.com";
// export const twiter = "dd";
// export const facebook = "jj";
// export const youtube = "kskkj";
