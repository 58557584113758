import React from "react";
import { useState } from "react";
import apiPost from "../Utilis/apiPost";
import { backend } from "../Utilis/config";
import { types } from "../Utilis/cat_types";
import { Link } from "react-router-dom";
import logo from "../SiteImages/logo.png";

function get_written_date(dateString) {
  if (!dateString) return ["", "", ""];
  const months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parts = dateString.split("/");
  parts[1] = months[parseInt(parts[1])];
  return parts;
}

export default function AllNews() {
  const [data, setData] = React.useState([]);
  const [offset, setOffset] = React.useState(1);

  React.useEffect(() => {
    async function fetchData() {
      const data = apiPost("/cms_front_get_subjects", {
        section_type: types.news,
        limit: 18,
        offset: offset,
      }).then((res) => {
        // console.log("News >>", res.data);
        setData(res.data);
      });
      // .then((res) => console.log(res, "res"));
    }
    fetchData();
  }, [offset]);
  const newsCount = data?.meta ? data?.meta[0]?.length / 18 : 0;
  let PagingItems = [];
  for (let i = 0; i <= newsCount; i++) {
    PagingItems.push(
      <li class="page-item">
        <button
          className={`btn ${offset == i ? "btn-primary" : "btn-light"}`}
          onClick={() => {
            setOffset(i);
          }}
        >
          {i + 1}
        </button>
      </li>
    );
  }
  return (
    <React.Fragment>
      <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark text-uppercase">
                <strong>أخبار الهيئة</strong>
              </h1>
            </div>
            {/* You can add breadcrumb here if needed */}
          </div>
        </div>
      </section>

      <div className="container-fluid mb-4">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="row justify-content-center">
              {/* Repeater loop */}
              {data?.data?.map((item) => (
                <div
                  key={item.id}
                  className="col-md-10 col-lg-4 mb-4 mb-lg-0 mt-4"
                >
                  <article className="custom-post-blog">
                    <span className="thumb-info custom-thumb-info-2">
                      <span className="thumb-info-wrapper">
                        <Link to={`/news/${item.id}`}>
                          <img
                            src={`${backend}/cms/imgs/${item.photo_id}`}
                            alt={item.title_ar}
                            className={logo}
                            style={{ height: "300px" }}
                          />
                        </Link>
                      </span>
                      <span className="thumb-info-caption custom-box-shadow">
                        <span className="thumb-info-caption-text">
                          <h4 className="font-weight-bold mb-4">
                            <Link
                              to={`/news/${item.id}`}
                              className="text-decoration-none custom-secondary-font text-color-dark"
                            >
                              {item.title_ar}
                            </Link>
                          </h4>
                          <p
                            style={{
                              fontWeight: 600,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.description_ar}
                          </p>
                        </span>
                        <span className="custom-thumb-info-post-infos text-center">
                          <ul>
                            <li className="text-uppercase">
                              {new Date(item.start_date).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}
                              <i className="icon-calendar icons"></i>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button
                class="page-link"
                onClick={() => {
                  setOffset(offset != 0 ? offset - 1 : 0);
                }}
              >
                السابق
              </button>
            </li>
            {PagingItems}
            <li class="page-item">
              <button
                class="page-link"
                onClick={() => {
                  setOffset(
                    offset + 1 > newsCount ? Math.floor(newsCount) : offset + 1
                  );
                }}
              >
                التالي
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
}
