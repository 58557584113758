import * as api from "./api.js";
import { create } from "apisauce";
import { backend } from "../config";
export let securePayload = null;
export const SetSecurePayload = (is_secured) => {
  securePayload = is_secured;
};
export const get_routing_info = () => {
  const fina = {
    route: "/cms/any",
  };
  return fina;
};
export const default_post = async (serviceUrl, params) => {
  if (securePayload === null || securePayload === undefined) await sync_sec();
  // console.log("INSIDE POSSSSSSSSSS");
  // console.log(params);
  // console.log(`===${serviceUrl}===`);
  // const { data, meta, error } = await api.post(true, serviceUrl, params);
  // useLoader && loaderTools.showLoader();
  if (!params) params = {};

  const result = await api.post(
    securePayload,
    serviceUrl,
    params,
    null,
    get_routing_info()
  );

  if (!Boolean(result))
    return { data: null, meta: null, error: "Server Error(1)", success: false };
  const { data, meta, error, origError } = result;
  // console.log("reso >", result);
  const keys = Object.keys(error);
  if (keys && keys.length > 0)
    return { data, meta, error, success: false, errorMsg: origError?.message };
  else return { data, meta, error: null, success: true };
};

export const sync_sec = async () => {
  try {
    const api = create({
      baseURL: backend,
    });
    const capa = await api.get(`/cpa`);
    if (!capa) return null;
    // console.log(capa.data);
    SetSecurePayload(capa.data.encrypt);
  } catch {
    return null;
  }
};
