import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";
import { types } from "../Utilis/cat_types";
import * as tun from "../Utilis/services/tunnle";
export default function MainLayout({ setUser, setEncMethod }) {
  window.Buffer = window.Buffer || require("buffer").Buffer;
  React.useEffect(() => {
    // tun.auto_login();
  }, []);

  return (
    <div>
      <Header setUser={setUser} setEncMethod={setEncMethod} />
      <Outlet />
      <Footer />
    </div>
  );
}
