import React, { useState } from "react";
import apiPost from "../Utilis/apiPost";
import { types } from "../Utilis/cat_types";
import { Link } from "react-router-dom";
import { backend } from "../Utilis/config";
import logo from "../SiteImages/logo.png";
import parse from "html-react-parser";

function get_written_date(dateString) {
  if (!dateString) return ["", "", ""];
  const months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const parts = dateString.split("/");
  parts[1] = months[parseInt(parts[1])];
  return parts;
}

export default function AllEvents() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const data = apiPost("/cms_front_get_subjects", {
        section_type: types.events,
      })
        .then((res) => setData(res.data.data))
        .then((res) => console.log(res, "res"));
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1
                className="text-dark text-uppercase"
                style={{ top: "-25px !important" }}
              >
                <strong>فعاليات الهيئة</strong>
              </h1>
            </div>
            {/* You can add breadcrumb here if needed */}
          </div>
        </div>
      </section>

      <div className="center">
        <hr />
        <h2 className="font-weight-bold title-bg">أحدث الفعاليات</h2>
        <div className="row">
          <div className="col">
            <div className="blog-posts">
              <section className="timeline">
                <div className="timeline-body">
                  {/* Repeater loop */}
                  {data?.map((item, index) => (
                    <article
                      key={index}
                      className={`timeline-box ${
                        index % 2 === 0 ?"left": "right" 
                      } post post-medium`}
                      style={{
                        marginTop:
                          index % 2 === 0 ? "unset" :  "-40px !important" ,
                        clear: index % 2 === 1 ? "unset" :  "none !important" ,
                      }}
                    >
                      <div className="timeline-box-arrow"></div>
                      <div className="p-2">
                        <div className="row mb-2">
                          <div
                            className="col"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="post-image">
                              <Link to={"/events/" + item.id}>
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={`${backend}/cms/imgs/${item.photo_id}`}
                                  className="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                                  alt={logo}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="post-content">
                              <h2 className="font-weight-semibold text-5 line-height-4 mt-2 mb-2">
                                <Link to={`/events/${item.id}`}>
                                  {item.title_ar}
                                </Link>
                              </h2>
                              <p>{parse(item.description_ar ?? "")}</p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div
                              className="post-meta"
                              style={{ direction: "rtl !important" }}
                            >
                              <span>
                                <i className="far fa-calendar-alt"></i>{" "}
                                {new Date(item.start_date).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </span>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div
                              className="post-meta"
                              style={{ direction: "rtl !important" }}
                            >
                              <span>
                                <i className="fas fa-map-marker-alt"></i>{" "}
                                {item.location_ar}{" "}
                              </span>
                              <span>
                                <i className="fas fa-landmark"></i>{" "}
                                {item.organizer_ar}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Link to={`/events/${item.id}`}>التفاصيل</Link>
                          </div>
                        </div>
                      </div>
                    </article>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* Your SQL Data Source will be handled differently in React */}
    </React.Fragment>
  );
}
