import React from "react";
import logo from "../SiteImages/logo.png";
import { delDateString, email, phone } from "../Utilis/config";
import { NavLink } from "react-router-dom";
import apiPost from "../Utilis/apiPost";
export default function Footer() {
  const [links, setLinks] = React.useState([]);
  const year = new Date().getFullYear();
  React.useEffect(() => {
    async function fetchData() {
      const data = apiPost("/cms_front_get_important_links", {})
        .then((res) => setLinks(res.data.data))
        .then((res) => console.log(res, "res"));
    }
    fetchData();
  }, []);
  return (
    <div>
      <footer
        id="footer"
        className="footer-texts-more-lighten rtl footer-padding"
      >
        <div className="">
          <div className="row py-4 my-5">
            <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
              <h5 className="text-4 text-color-light mb-3">بيانات الإتصال</h5>
              <ul className="list list-unstyled">
                <li className="pb-1 mb-2">
                  <span className="d-block font-weight-normal line-height-1 text-color-light">
                    العنوان
                  </span>
                  العاصمة الادارية الجديدة 88 شارع النزهة خلف السبع عمارات
                  هليوبوليس القاهرة-
                </li>
                <li className="pb-1 mb-2">
                  <span className="d-block font-weight-normal line-height-1 text-color-light">
                    الهاتف
                  </span>
                  <a href="tel:15556">15556</a>
                </li>
                <li className="pb-1 mb-2">
                  <span className="d-block font-weight-normal line-height-1 text-color-light">
                    البريد الإلكتروني
                  </span>
                  <a href="mailto:customercare@upa.gov.eg">
                    <span>customercare@upa.gov.eg</span>
                  </a>
                </li>
                <li className="pb-1 mb-2">
                  <span className="d-block font-weight-normal line-height-1 text-color-light">
                    مواعيد العمل{" "}
                  </span>
                  من السبت للخميس : من الساعة 9 إلي الساعة 4
                </li>
              </ul>
              <ul className="social-icons social-icons-clean-with-border social-icons-medium">
                <li className="social-icons-instagram">
                  <a
                    href="https://www.instagram.com/unified.procurement.authority/"
                    className="no-footer-css"
                    target="_blank"
                    title="Instagram"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li className="social-icons-linkedin">
                  <a
                    href="https://www.linkedin.com/company/upa-egypt/"
                    className="no-footer-css"
                    target="_blank"
                    title="LinkedIn"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li className="social-icons-twitter mx-2">
                  <a
                    href="https://twitter.com/UPA_Egypt"
                    className="no-footer-css"
                    target="_blank"
                    title="Twitter"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="social-icons-facebook">
                  <a
                    href="https://www.facebook.com/UPA.Egypt"
                    className="no-footer-css"
                    title="Facebook"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-4 mb-5 mb-md-0">
              <h5 className="text-4 text-color-light mb-3">
                موقعنا على الخريطة{" "}
              </h5>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.7037610270772!2d31.742261576214844!3d30.01666147493763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1457f36e4c054f57%3A0x43370d21a01f6e9e!2z2YfZitim2Kkg2KfZhNi02LHYp9ihINin2YTZhdmI2K3YryDYqNin2YTYudin2LXZhdipINin2YTYpdiv2KfYsdmK2Kk!5e0!3m2!1sen!2seg!4v1716206360364!5m2!1sen!2seg"
                width={"450"}
                height="260"
                frameBorder="1"
                style={{ border: "1px; border: azure" }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
            <div className="col-md-6 col-lg-2 mb-5 mb-lg-0">
              <h5 className="text-4 text-color-light mb-3">روابط هامة</h5>
              <ul className="list list-unstyled mb-0">
                {links.map((x) => (
                  <li className="mb-0">
                    <a href={x.url} target="_blank">
                      {x.name_ar}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-6 col-lg-3">
              <h5 className="text-4 text-color-light mb-3">الخط الساخن </h5>
              <p>
                {" "}
                يسعدنا تقديم المساعدة طوال أيام الأسبوع وعلى مدار اليوم بالإتصال
                بالرقم التالي{" "}
              </p>
              <div
                style={{
                  fontSize: "92px",
                  textAlign: "center",
                  paddingTop: "15px",
                }}
              >
                15556
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-copyright footer-copyright-style-2 pt-4 pb-5">
            <div className="row">
              <div className="col-12 text-center">
                <p className="mb-0">
                  جميع الحقوق محفوظة لهيئة الشراء الموحد والإمداد والتموين الطبي
                  وإدارة التكنولوجيا الطبية &copy; {year}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
