import React from "react";
// import { useState } from "react";
import apiPost from "../Utilis/apiPost";
import { types } from "../Utilis/cat_types";
import { backend } from "../Utilis/config";

export default function AllTenders({ limit }) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const data = apiPost("/cms_front_get_public_tenders", { limit }).then(
        (res) => setData(res.data.data)
      );
      // .then((res) => console.log(res, "res"));
    }
    fetchData();
  }, []);

  const tenders = [
    {
      title: "Title 1",
      description:
        "Description 3 Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3",

      image: "img/generic/generic-corporate-17-1.jpg",
      image_alt: "Image Alt 1",
      lib_file: "path/to/file1.pdf",
    },
    {
      title: "Title 2",
      description:
        "Description 3 Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3",

      image: "img/generic/generic-corporate-17-2.jpg",
      image_alt: "Image Alt 2",
      lib_file: "path/to/file2.pdf",
    },
    {
      title: "Title 3",
      description:
        "Description 3 Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3Description 3",
      image: "img/generic/generic-corporate-17-3.jpg",
      image_alt: "Image Alt 3",
      lib_file: "path/to/file3.pdf",
    },
    // Add more objects as needed
  ];
  return (
    <>
      <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark text-uppercase">
                <strong>المناقصات </strong>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <div>
        <div role="main" className="main">
          <div className="container-fluid">
            <div className="row">
              {data?.map((event, index) => (
                <div
                  className="col-md-3"
                  style={{
                    fontSize: "13px",
                    minHeight: "300px",
                    marginBottom: "10px",
                    maxHeight: "300px",
                    font: "almarai",
                  }}
                >
                  <section
                    style={{ height: "100%" }}
                    className="call-to-action call-to-action-quaternary button-centered mb-xl"
                  >
                    <div className="call-to-action-content">
                      <h3>{event.name.substring(0, 100)}</h3>
                    </div>
                    <div className="call-to-action-btn">
                      <a
                        href={`${backend}/cms/imgs/${event.doc_id}`}
                        target="_blank"
                        className="btn btn-lg btn-primary"
                      >
                        تحميل الملف
                      </a>
                    </div>
                  </section>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
