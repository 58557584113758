import { create } from "apisauce";
import { backend } from "../config";

import * as tunnle from "./tunnle";
import * as utils from "./utils";

// import
// import useUPAAuth from "../../../hooks/AuthHook";

export const errorCodes = {
  syncError: 1,
  serverError: 2,
};
export const get = async (url, params, axiosConfig) => {
  const response = await get(url, params, axiosConfig);
  if (response.ok) {
    return response;
  }
  return null;
};
export const decrypt_response = async (response) => {
  // console.log("_+_+_+_+>", response.data);
  const reso = await tunnle.defaultAesDecrypt(response.data);
  response.data = JSON.parse(reso);
  // console.log("THEREE>>", response.data);

  // console.log("XCXXXXXXX >> ", reso);
};
