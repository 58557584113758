import React from "react";
import { Link, NavLink } from "react-router-dom";
import postApi from "../Utilis/apiPost";
import logo from "../SiteImages/logosvg.svg";
import apiPost from "../Utilis/apiPost";

import { delDateString, email, phone } from "../Utilis/config";
export default function Header({ setUser, setEncMethod }) {
  // console.log(setUser, setEncMethod);
  const [activeLink, setActiveLink] = React.useState(null);
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    // console.log("Hey >>");
    async function fetchData() {
      const data = apiPost("/get_cms_pages", {})
        .then((res) => setPages(res.data.data))
        .then((res) => {
          // console.log(res, "res");
        });
    }
    fetchData();
  }, []);
  // console.log(pages, "pages");
  return (
    <>
      <header
        id="header"
        className="header"
        // data-plugin-options="{'stickyEnabled': 'true', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}"
      >
        <div className="header-body border-top-0">
          <div className="header-container container">
            <div className="header-row">
              <div className="header-column">
                <div className="header-row justify-content-end">
                  <div className="header-nav p-0">
                    <div
                      className="header-nav header-nav-links"
                      style={{
                        backgroundColor: "white",
                        top: "0",
                        left: "0",
                        zIndex: "100100",
                        position: "fixed",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <a
                        type="button"
                        className="btn btn-outline btn-rounded btn-dark  btn-with-arrow mb-2"
                        href="https://sc.upa.gov.eg/"
                        target="_blank"
                      >
                        <span>
                          <i className="fas fa-chevron-right"></i>
                        </span>
                        منظومة الإمداد الطبي
                      </a>
                      <div
                        style={{ fontSize: "18px !important" }}
                        className="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-dropdown-arrow header-nav-main-dropdown-center header-nav-main-dropdown-center-bottom header-nav-main-effect-3 header-nav-main-sub-effect-1"
                      >
                        <nav className="collapse">
                          <ul
                            className="nav nav-pills flex-column flex-lg-row rtl"
                            id="mainNav"
                          >
                            <li className="dropdown">
                              <NavLink to={"/"} className="dropdown-toggle">
                                الرئيسية
                              </NavLink>
                            </li>
                            <li className="dropdown">
                              <a className="dropdown-toggle">عن الهيئة</a>
                              <ul
                                className="dropdown-menu"
                                style={{
                                  height: "fit-content",
                                  backgroundColor: "white",
                                }}
                              >
                                {pages.map((x) => (
                                  <li
                                    style={{
                                      padding: "5px",
                                      fontSize: "18px",
                                      width: "100%",
                                    }}
                                  >
                                    <NavLink to={"/pages/" + x.id}>
                                      {x.name_ar}
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </li>
                            {/* Additional navigation links */}
                            <li className="dropdown">
                              <NavLink to={"/news"} className="dropdown-toggle">
                                الأخبار
                              </NavLink>
                            </li>
                            <li className="dropdown">
                              <NavLink
                                to={"/events"}
                                className="dropdown-toggle"
                              >
                                أجندة الأحداث
                              </NavLink>
                            </li>
                            <li className="dropdown">
                              <NavLink
                                to={"/tenders"}
                                className="dropdown-toggle"
                              >
                                المناقصات
                              </NavLink>
                            </li>
                            <li className="dropdown">
                              <NavLink
                                to={"/publications"}
                                className="dropdown-toggle"
                              >
                                الإصدارات
                              </NavLink>
                            </li>
                            <li className="dropdown">
                              <NavLink to={"/faq"} className="dropdown-toggle">
                                الأسئلة الشائعة
                              </NavLink>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div>
                        <ul className="social-icons social-icons-clean-with-border social-icons-medium">
                          <li
                            className="mx-2"
                            style={{ backgroundColor: "#7c4a3a" }}
                          >
                            <a
                              href="https://www.instagram.com/unified.procurement.authority/"
                              target="_blank"
                              title="Instagram"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li
                            className="mx-2"
                            style={{ backgroundColor: "#0073b2" }}
                          >
                            <a
                              href="https://www.linkedin.com/company/upa-egypt/"
                              className="no-footer-css"
                              target="_blank"
                              title="LinkedIn"
                            >
                              <i className="fab fa-linkedin"></i>
                            </a>
                          </li>
                          <li
                            className="mx-2"
                            style={{ backgroundColor: "#1aa9e1" }}
                          >
                            <a
                              href="https://twitter.com/UPA_Egypt"
                              className="no-footer-css"
                              target="_blank"
                              title="Twitter"
                            >
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li
                            className="mx-2"
                            style={{ backgroundColor: "#3b5a9a" }}
                          >
                            <a
                              href="https://www.facebook.com/UPA.Egypt"
                              className="no-footer-css"
                              title="Facebook"
                              target="_blank"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <img
                      src={logo}
                      style={{
                        width: "100%",
                        height: "95px",
                        marginTop: "60px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className="header-top top-header"
            style={{ textAlign: "center", fontFamily: "DroidArabicKufiBold" }}
          >
            الهيئة المصرية للشراء الموحد والإمداد والتموين الطبي وإدارة
            التكنولوجيا الطبية
          </div> */}

          <div
            className="header-nav-bar header-nav-bar-top-border"
            style={{ position: "sticky !important", top: "0 !important" }}
          ></div>
        </div>
      </header>
    </>
  );
}
