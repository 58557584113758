import { backend } from "../config";
import { generateOTP } from "./otp.js";
import CryptoJS from "crypto-js";
import crypto from "crypto";
import { v4 as uuid } from "uuid";

import * as common from "./common.js";
import { create } from "apisauce";
export const syncErrors = {
  inSync: 1,
  invlidServerKeyId: 2,
  invalidSharedKey: 3,
  outOfSync: 4,
};
const keyFormat = "hex";
const ECDH_KeysObject = crypto.createECDH("secp521r1");
// import crypto from "crypto-browserify";

const sessionObject = {};
export const initClientKeys = async () => {
  const api = create({
    baseURL: backend,
  });
  const serverPublicResponse = await api.get(`/auth/pk`, {});
  const serverPublic = serverPublicResponse.data.publicKey;
  const serverKeyId = serverPublicResponse.data.keyId;
  const serverPublicKey = hex2Arr(serverPublic);
  try {
    const clientPublicKeyHex =
      ECDH_KeysObject.generateKeys().toString(keyFormat); // =====> Client Public Key
    sessionObject.publicKey = clientPublicKeyHex;
    sessionObject.sharedKey = GetSahredKey(serverPublicKey);
    sessionObject.serverKeyId = serverKeyId;
    // console.log("?>>>>>>>>>>>>>>>>>>>>YEEEEEEEEEEEEEEEEEEE");
    return validateSsession();
  } catch (err) {
    console.log("Auth ERRRR");
    // console.error(err);
    return syncErrors.outOfSync;
  }
};

export const GetSahredKey = (clientSharedKey) => {
  try {
    return ECDH_KeysObject.computeSecret(clientSharedKey, keyFormat, keyFormat);
  } catch (err) {
    // All errors to be denied without response
    return null;
  }
};
export const setToken = (token) => {
  sessionObject.token = token;
};
export const getToken = async () => {
  if (sessionObject.token == null) await auto_login();
  return sessionObject.token;
};
export const validateSsession = async () => {
  try {
    const api = create({
      baseURL: backend,
    });
    const checkObject = await createTunnleObject();
    const result = await api.post(`/auth/shared`, checkObject);

    // console.log(+result.data);
    if (+result.data === syncErrors.inSync)
      generateOTP(sessionObject.sharedKey);
    return +result.data;
  } catch (e) {
    return syncErrors.outOfSync;
  }
};
export const createTunnleObject = async () => {
  const nounceHash = await hmacSha256(
    sessionObject.sharedKey,
    sessionObject.serverKeyId
  );
  return {
    // sharedSecret: sessionObject.sharedKey,
    publicKey: sessionObject.publicKey,
    serverKeyId: sessionObject.serverKeyId,
    nounceHash,
    otp: await generateOTP(sessionObject.sharedKey),
    // aes: await aesEncrypt(sessionObject.sharedKey, JSON.stringify(config)),
  };
};
export const aesEncrypt = async (sharedKey, message) => {
  const enc = await CryptoJS.AES.encrypt(message, sharedKey);
  return enc.toString();
};
export const aesDecrypt = async (sharedKey, message) => {
  const enc = await CryptoJS.AES.decrypt(message, sharedKey);
  return enc.toString(CryptoJS.enc.Utf8);
};
export const defaultAesEncrypt = async (message) => {
  const data = await aesEncrypt(sessionObject.sharedKey, message);
  return {
    data,
    publicKey: sessionObject.publicKey,
    serverKeyId: sessionObject.serverKeyId,
  };
};
export const defaultAesDecrypt = async (message) => {
  const res = await aesDecrypt(sessionObject.sharedKey, message);
  return res.toString(CryptoJS.enc.Utf8);
  // return {
  //   data,
  //   publicKey: sessionObject.publicKey,
  //   serverKeyId: sessionObject.serverKeyId,
  // };
};
const hmacSha256 = async (key, message) => {
  const sha256 = await CryptoJS.HmacSHA256(message, key);
  return CryptoJS.enc.Hex.stringify(sha256);
};
const hex2Arr = (str) => {
  if (!str) {
    return new Uint8Array();
  }
  const arr = [];
  for (let i = 0, len = str.length; i < len; i += 2) {
    arr.push(parseInt(str.substr(i, 2), 16));
  }
  return new Uint8Array(arr);
};

export const buf2Hex = (buf) => {
  return Array.from(new Uint8Array(buf))
    .map((x) => ("00" + x.toString(16)).slice(-2))
    .join("");
};

export const auto_login = async () => {
  try {
    const syncState = await initClientKeys();
    if (syncState !== syncErrors.inSync) {
      alert("Server Out Of Sync");
      return;
    }
    /** NOT SECURED */
    const api = create({
      baseURL: backend,
    });

    const encData = await defaultAesEncrypt(uuid());
    // console.log(encData);
    const result = await api.post(
      "/auth/95736b60a48049688ad52c1d354db283",
      encData
    );
    // console.log(result);
    if (result.status !== 200) {
      console.log("AAAAAAAAAAAAAAAA>");
      return null;
    }
    // console.log(result.data);
    if (Boolean(result.data)) {
      await common.decrypt_response(result);
      setToken(result.data.token);
    }
    return result.data;
  } catch (error) {
    console.log("EXCEPTION");
    return null;
  }
};
