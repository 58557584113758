import React from "react";
import { Link, NavLink } from "react-router-dom";
import apiPost from "../Utilis/apiPost";
import { types } from "../Utilis/cat_types";
import { backend } from "../Utilis/config";
export default function News() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const data = apiPost("/cms_front_get_subjects", {
        section_type: types.news,
        limit: 6,
        offset: 0,
      })
        .then((res) => setData(res.data.data))
        .then((res) => {
          // console.log(res, "res");
        });
    }
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark text-uppercase">
                <strong>أخبار الهيئة</strong>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid ">
        <div className="row justify-content-center">
          <div className="col-11">
            <div className="row justify-content-center">
              {/* Repeater loop */}
              {data?.map((item) => (
                <div
                  key={item.subject_id}
                  className="col-md-10 col-lg-4 mb-4 mb-lg-0 mt-4"
                >
                  <article className="custom-post-blog">
                    <span className="thumb-info custom-thumb-info-2">
                      <span className="thumb-info-wrapper">
                        <Link to={`/news/${item.id}`}>
                          <img
                            src={`${backend}/cms/imgs/${item.photo_id}`}
                            className="img-fluid"
                            style={{ height: "300px" }}
                          />
                        </Link>
                      </span>
                      <span className="thumb-info-caption custom-box-shadow">
                        <span className="thumb-info-caption-text">
                          <h4 className="font-weight-bold mb-4">
                            <Link
                              to={`/news/${item.id}`}
                              className="text-decoration-none custom-secondary-font text-color-dark"
                            >
                              {item.title_ar}
                            </Link>
                          </h4>
                          <p
                            style={{
                              minHeight: 50,
                              fontWeight: 600,
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.description_ar}
                          </p>
                        </span>
                        <span className="custom-thumb-info-post-infos text-center">
                          <ul>
                            <li className="text-uppercase">
                              {new Date(item.start_date).toLocaleDateString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}
                              <i className="icon-calendar icons"></i>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {data.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <NavLink
            to={"/news"}
            className="btn btn-primary btn-rounded"
            style={{ fontSize: 18 }}
          >
            عرض المزيد
          </NavLink>
        </div>
      )}
    </React.Fragment>
  );
}
