import React from "react";
import apiPost from "../Utilis/apiPost";
import parse from "html-react-parser";
const FAQ = () => {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const data = apiPost("/cms_front_get_faqs", {})
        .then((res) => setData(res.data.data))
        .then((res) => console.log(res, "res"));
    }
    fetchData();
  }, []);
  const [collapseId, setCollapseID] = React.useState();
  const faqs = [
    {
      faq_id: 1,
      faq_question_ar: "سؤال 1",
      faq_answer_ar: "إجابة على السؤال 1",
    },
    {
      faq_id: 2,
      faq_question_ar: "سؤال 2",
      faq_answer_ar: "إجابة على السؤال 2",
    },
    {
      faq_id: 3,
      faq_question_ar: "سؤال 3",
      faq_answer_ar: "إجابة على السؤال 3",
    },
    // Add more dummy data as needed
  ];
  const collapseAnswer = (id) => {
    setCollapseID(id);
  };
  return (
    <div>
      <style>
        {`
                .py-4 {
                    padding-right: 1% !important;
                    padding-left: 1% !important;
                }
                span {
                    font-family: DroidArabicKufiRegular !important;
                }
                `}
      </style>
      <section className="page-header page-header-modern bg-color-light-scale-1 page-header-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 align-self-center p-static order-2 text-center">
              <h1 className="text-dark text-uppercase">
                <strong>الأسئلة الشائعة</strong>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <div className="py-4">
        <div className="accordion" id="accordionQuaternary">
          {data?.map((faq) => (
            <div className="card card-default" key={faq.id}>
              <div
                className="card-header bg-color-primary"
                id={`collapseQuaternaryHeadingOne${faq.id}`}
                onClick={() => collapseAnswer(faq.id)}
              >
                <h4 className="card-title m-0">
                  <a
                    className="accordion-toggle text-color-light"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${faq.id}`}
                    aria-expanded="false"
                    aria-controls={`collapse${faq.id}`}
                  >
                    {faq.question_ar}
                  </a>
                </h4>
              </div>
              <div
                id={faq.id}
                className="collapse show"
                aria-labelledby={`collapseQuaternaryHeadingOne${faq.id}`}
                data-bs-parent="#accordionQuaternary"
              >
                {faq.id === collapseId && (
                  <div className="card-body">
                    <p
                      style={{
                        fontFamily: "DroidArabicKufiRegular !important",
                      }}
                      className="mb-0 title-sm"
                    >
                      {parse(faq.full_answer_ar)}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
